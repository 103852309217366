
const Maintenance = () => {



    return <div className="h-100 w-100 d-flex align-items-center">
        <div className="col-md-6 text-center mx-auto">
            <img className="w-auto mx-auto" style={{height: 60}} src='https://www.varar.co/img/logo/red.png' />
            <h1 className="font-semibold">
                Maintenance en cours
            </h1>
            <p>
                Varar est en cours de maintenance. Une nouvelle version sera disponible très prochainement.
            </p>
        </div>
    </div>
}

export default Maintenance;